<script>
  export let tile;
  export let title = '';
  export let draggable = false;
  export let available = true;
  export let mini = false;
  export let playedFrom = {x: 0, y: 0};
  export let dropTarget = ".droppable";

  import { onDestroy } from 'svelte';
  import { fly } from 'svelte/transition';
  import { relocate } from "../helpers/dragHelper.js";
  import elements from '../helpers/elements.js';

  let element, groupBlock;
  $: element = elements.byNumber[tile.value] || elements.bySymbol[tile.name];
  $: groupBlock = element && element.groupBlock || tile.groupBlock;

  let self;
  onDestroy(() => {
    if (self && self.parentElement) {
      self.parentElement.dispatchEvent(new CustomEvent(
        'tile-destroy',
        {detail: { item: self }},
      ));
    }
  });

  const handleMousedown = relocate('.draggable', dropTarget, '.tile.draggable');
</script>

<style>
  .tile {
    display: inline-block;
    font-size: small;
    font-family: Arial, Helvetica, sans-serif;
    color: black;
    border: solid 1px rgba(0 0 0 0);
    border-radius: 5px;
    touch-action: none;
    user-select: none;
    cursor: default;
  }

  .tileSymbol {
    font-size: 150%;
    font-weight: bold;
    display: block;
    text-align: center;
  }

  .tileSymbol.unknown {
    font-size: 200%;
    position: relative;
    top: 50%;
    transform: translateY(-50%);
  }

  .tileValue {
    float: right;
    font-weight: bold;
    margin: 2px 2px 0 0;
  }

  .unavailable {
    filter: grayscale(80%) opacity(20%);
  }
</style>

<span
  bind:this={self}
  on:mousedown|preventDefault={handleMousedown}
  on:touchstart|passive={handleMousedown}
  class="
    tile {groupBlock.replace(/\s/g, '-')}
    {available ? 'available' : 'unavailable'}
    {draggable ? 'draggable' : ''}
  "
  in:fly="{{ x: playedFrom.x, y: playedFrom.y, duration: 500 }}"
  title="{title || (element && element.name) || groupBlock}"
  data-name={(element && element.symbol)}
>
  {#if element}
    <span class="tileSymbol">{element.symbol}</span>
    {#if !mini}
      <span class="tileValue">{element.atomicNumber}</span>
    {/if}
  {:else}
    <span class="unknown tileSymbol fas fa-atom"></span>
  {/if}
</span>
